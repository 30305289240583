import { getIn } from "formik";
import { useEffect, useState } from "react";

const InputMask = require("react-input-mask");

const CustomTimeInput: React.FC<any> = ({
  onChange,
  value,
  placeholder,
  field,
  form: { touched, errors },
  className,
}) => {
  const [mask, setMask] = useState<any>(null);
  const fieldError = getIn(errors, field.name);
  const fieldTouched = getIn(touched, field.name);

  const handleTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = e.target.value;

    // Update mask for up to three digits in the hours part
    setMask([/[0-9]/, /[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]);

    // Ensure value adheres to the max limit of 999:99 and min limit of 000:00
    const [hours, minutes, seconds] = newValue.split(":");
    let hoursNumber = parseInt(hours || "0", 10);
    let minutesNumber = parseInt(minutes || "0", 10);
    let secondsNumber = parseInt(seconds || "0", 10);

    if (hoursNumber > 999) hoursNumber = 999;
    if (minutesNumber > 59) minutesNumber = 59;
    if (secondsNumber > 59) secondsNumber = 59;

    newValue = `${hoursNumber.toString().padStart(3, "0")}:${minutesNumber
      .toString()
      .padStart(2, "0")}:${secondsNumber
      .toString()
      .padStart(2, "0")}`;

    onChange(newValue);
  };

  useEffect(() => {
    setMask([/[0-9]/, /[0-9]/, /[0-9]/, ":", /[0-5]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]);
  }, []);

  return (
    <InputMask
      mask={mask}
      value={value}
      maskPlaceholder={null}
      onChange={handleTimeChange}
      onFocus={() => {
        if (field.value === "") {
          onChange("000:00:00");
        }
      }}
    >
      <label className={`float-label-input w-full`}>
        <input
          type="text"
          className={`border-b outline-none border-gray_1 w-full ${className}`}
          placeholder=" "
        />
        <span className="text-gray_2">{placeholder}</span>
        {fieldTouched && fieldError ? (
          <div className="text-red text-sm text-left">
            {fieldError as string}
          </div>
        ) : null}
      </label>
    </InputMask>
  );
};

export default CustomTimeInput;
